import KatalLogger, { Level } from '@amzn/katal-logger';

const katalLoggerConfig = {
  url: 'https://kcczjipx0g.execute-api.us-east-1.amazonaws.com/prod/v1/log',
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: process.env.NODE_ENV === 'development' ? true : false,
};

export const logger = new KatalLogger(katalLoggerConfig);
logger.addErrorListener();
